@use '../styles/theme';

:local(.button) {
  height: 48px;
  min-height: 48px;
  width: min-content;
  min-width: 156px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 8px;
  border-width: 0;
  border-color: transparent;
  transition: background-color 0.1s ease-in-out;
  white-space: nowrap;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
}

:local(.basic), :local(.transparent) {
  color: grey;
  border: 2px solid theme.$basic-border-color;
  background-color: theme.$basic-color;

  svg {
    color: theme.$text4-color;
  }

  &:hover {
    color: theme.$text4-color-hover;
    background-color: theme.$basic-color-hover;
  }

  &:active {
    color: theme.$text4-color-pressed;
    background-color: theme.$basic-color-pressed;
  }
}

:local(.transparent) {
  border-color: theme.$transparent;
  background-color: #1ab4fe;
}

:local(.primary) {
  color: theme.$text5-color;
  background-color: #1ab4fe;
  border-radius: 15px;

  svg {
    color: theme.$text5-color;
  }

  border-style: solid;
  border-color: #1ab4fe;
  border-width: 2px;

  &:hover {
    color: #1ab4fe;
    background-color: white;
  }
}

:local(.accept) {
  color: theme.$text5-color;
  background-color: theme.$accept-color;
  border: 2px solid theme.$accept-border-color;
  
  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accept-color-hover;
    border: 2px solid theme.$accept-border-color;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accept-color-pressed;
  }
}

:local(.cancel) {
  color: theme.$text5-color;
  background-color: theme.$cancel-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$cancel-color-hover;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$cancel-color-pressed;
  }
}

:local(.accent1) {
  color: theme.$text5-color;
  background-color: theme.$accent1-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accent1-color-hover;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accent1-color-pressed;
  }
}

:local(.accent2) {
  color: theme.$text5-color;
  background-color: theme.$accent2-color;
  border: 2px solid theme.$accent2-border-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accent2-color-hover;
    border: 2px solid theme.$accent2-border-color
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accent2-color-pressed;
  }
}

:local(.accent3) {
  color: theme.$text5-color;
  background-color: theme.$accent3-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accent3-color-hover;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accent3-color-pressed;
  }
}

:local(.accent4) {
  color: theme.$text5-color;
  background-color: theme.$accent4-color;
  border: 2px solid theme.$accent4-border-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accent4-color-hover;
    border: 2px solid theme.$accent4-border-color;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accent4-color-pressed;
  }
}

:local(.custom1) {

  background-color: white;
  border: 2px solid white;
  border-radius: 25px;
  color: black;
  font-family: Montserrat, sans-serif;
  font-size: 20px;

  svg {
    color: black;
  }

  &:hover {
    color: white;
    background-color: black;
    border: 2px solid black;
  }

  &:active {
    color: black;
    background-color: white;
  }
}

:local(.accentEQ) {
  color: theme.$text5-color;
  background-color: theme.$accentEQ-color;
  border: 2px solid theme.$accentEQ-border-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accentEQ-color-hover;
    border: 2px solid theme.$accentEQ-border-color;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accentEQ-color-pressed;
  }
}

:local(.accent5) {
  color: theme.$text5-color;
  background-color: theme.$accent5-color;
  border: 2px solid theme.$accent5-border-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accent5-color-hover;
    border: 2px solid theme.$accent5-border-color;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accent5-color-pressed;
  }
}


:local(.accent6) {
  color: theme.$text5-color;
  background-color: theme.$accent6-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    color: theme.$text5-color-hover;
    background-color: theme.$accent6-color-hover;
  }

  &:active {
    color: theme.$text5-color-pressed;
    background-color: theme.$accent6-color-pressed;
  }
}

:local(.button):disabled {
  border-color: transparent;
  cursor: not-allowed;
  color: theme.$disabled-text-color;
  opacity: 0.7;
  background-color: theme.$disabled-bg-color;
}

:global(.keyboard-user) :local(.button):focus {
  border-color: transparent;
}

:local(.sm) {
  height: 32px;
  min-height: 32px;
  min-width: 100px;
  padding: 0 8px;
}

:local(.lg), :local(.xl) {
  border-radius: 32px;
  padding: 0 48px;
  font-size: 16px;
}

:local(.lg) {
  height: 48px;
}

:local(.xl) {
  height: 64px;
}

:local(.thin) {
  min-height: 41px;
  height: 41px;
  &:hover {
    color: theme.$text5-color-hover;
  }
}

:local(.thick) {
  height: 53px;
}

:local(.signin) {
  border: 2px solid #007AB8;
  background-color: transparent;
  color: #007AB8;
  box-sizing: border-box;
  border-radius: 13px;
}

:local(.landing) {
  border: 2px solid #007AB8;
  background-color: #007AB8; 
  color: theme.$text5-color;
  box-sizing: border-box;
  border-radius: 13px;
  &:hover {
    background-color: #008bd1;
  }
  &:active {
    background-color: #00699E;
  }
}
