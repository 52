:local(.avatar-sdk) {

  background-color: transparent;
  display: flex;
  height: 45vh;

  :local(.loading-mask) {
    position: absolute;
    top: 40vh;
    bottom: 40vh;

    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 99;

    label {
      justify-content: center;
      text-align: center;
      font-size: xx-large;
      font-style: normal;
      font-weight: 100;
      width: 50vw;
    }
  }

  :local(.buttons) {
    align-items: center;
    font-style: normal;
    font-weight: 100;
    text-align: center;
    display: flex;
    z-index: 99;
    margin: auto;
    width: 100%;
  }

  :local(.mobileButtons) {
    align-items: center;
    font-family: 'Monteserrat', sans-serif !important;
    font-style: normal;
    font-weight: 100;
    text-align: center;
    display: block;
    z-index: 99;
    margin: auto;
    width: 100%;
    height: 70vh;
  }

  :local(.desktopImgButton) {
    display: block;
    margin: auto;
    width: 100%;
    border: none;
  }

  :local(.mobileImgButton) {
    display: block;
    width: 100%;
    border: none;
    height: 20vh;
  }

  :local(.mobileCrop) {
    height: 15vh;
    overflow: hidden;
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  :local(.desktopSpan) {
    display: flex;
    margin-left: 1.66%;
    margin-right: 1.66%;
    width: 30%;
  }

  :local(.mobileSpan) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    height: 23.33vh;
  }

  :local(.mobileImg) {
    width: 100%;
    margin-bottom: auto;
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
  }

  :local(.desktopImg) {
    margin-bottom: 40px;
    width: clamp(0px, 100%, 30vw);
    max-height: 30vh;
    margin-left: auto;
    margin-right: auto;
  }

  :local(.imgMobile) {
    width: clamp(0px, 100%, 30vh);
    max-height: 50vh;
    margin-bottom: 20px;
  }

  :local(.mobileButton) {
    background-color: transparent;
    border: 3px solid #1ab4fe;
    color: #000;
    cursor: pointer;
    display: block;
    font-size: clamp(6px, 3vw, 22px);
    font-style: bold;
    font-weight: 600;
    height: 5vh;
    letter-spacing: 0.2vw;
    margin: auto;
    max-height: 50px;
    min-width: 0px;
    padding-bottom: 0.3vh;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    width: 100%;
    height: 5vh;

    &:hover {
      background-color: #1ab4fe;
      color: #fff;    
    }

    &:focus {
      outline: none;    
    }

  }

  :local(.desktopButton) {

    background-color: transparent;
    border: 3px solid #1ab4fe;
    border-radius: 20px;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    font-family: 'Monteserrat', sans-serif !important;
    font-size: clamp(6px, 1vw, 22px);
    font-style: normal;
    font-weight: 100;
    height: 5vh;
    margin: auto;
    max-height: 50px;
    max-width: 300px;
    min-width: 0px;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: #1ab4fe;
      color: #fff;    
    }

    &:focus {
      outline: none;    
    }

  }
}